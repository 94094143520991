import React from 'react'
import styled from 'styled-components'

import { InnerSection, SocialLink } from '../App'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

export default function AboutSection() {
    return <Section id="about">
        <InnerSection $flex={true}>
            <div style={{ flex: 1 }}>
                <h1>About FeatherOak: CCG</h1>
                <p>
                    Dive into epic battles against fellow players in this exciting upcoming mobile card game. Form your deck using cards from <strong>four different factions</strong>, across multiple archetypes and defeat your opponent in the play-style you prefer. Place, move and attack units on a hexagonal grid, cast powerful spells, and defend your hero on your way to victory!
                </p>
                <p>
                    FeatherOak will launch with <strong>450 cards</strong> and 8 heroes to choose from; with more to come in the future!
                </p>
                <p>
                    We are currently hard at work and will release the game in the near future! To stay updated, consider following us:
                </p>
                <p style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 30 }}>
                    <SocialLink href="//instagram.com/featheroakgame/" target="blank">
                        <FontAwesomeIcon icon={faInstagram} />
                        <span>Follow us</span>
                    </SocialLink>
                    <SocialLink href="//facebook.com/featheroakgame" target="blank">
                        <FontAwesomeIcon icon={faFacebook} />
                        <span>Like us</span>
                    </SocialLink>
                </p>
            </div>
            <ScreenShotContainer>
                <img widt={250} height={542} alt="Screenshot of the game" src="images/screenshot.jpg" />
            </ScreenShotContainer>
        </InnerSection>
    </Section>
}

const Section = styled.section`
    background-image: url(images/bg.png);
    background-repeat: repeat;
    padding: 2rem;

    border-top: 2px solid var(--gold);
    
    display: flex;
    justify-content: center;
    align-items: center;
`

const ScreenShotContainer = styled.div`
    margin-left: 30px;
    margin-top: 30px;


    img {
        border-radius: 14px;
        width: 100%;
        max-width: 200px;
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        margin-left: 0;
    }
`