import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import { InnerSection } from '../App'

export default function ShowcaseSection() {
    const heroes = [
        {
            code: 'CO001',
            faction: 'justice',
            name: 'Godefroy: Commander of Ollaria',
            description: 'Ollaria was a powerful nation that was instantly burned to the ground by the mages of Academia during the first war. Despite the Academians claiming it was an accident, Regnier has vowed revenge upon anyone involved in the atrocity.',
            gameplay: 'Godefroy focuses on swarming the board with Ollaria units by lowering the cost to summon an ally each round.'
        },
        {
            code: 'CO002',
            faction: 'justice',
            name: 'Regnier: Ollarian Harbinger',
            description: 'As the entirety of the Ollarian nation was wiped off the map, most of its citizens now roam the world in cluttered armies. Regnier leads a small batallion in search of resources.',
            gameplay: 'Regnier saves up Reinforcement tokens whenever an ally dies, which can in turn be used to summon units to the board without paying the cost.'
        },
        {
            code: 'CO003',
            faction: 'arcane',
            name: 'Jorildyn: Wise Scholar',
            description: 'Academia is a tower at the edge of the Sylvanii Forest which houses many old tomes and scrolls containing powerful magic. Jorildyn is one of its oldest denizens and his sanity is hanging by a thread.',
            gameplay: 'Jorildyn makes it easier to equip cards with Academia Tomes, powerful equipment cards that boost the magical attacks of Mage units.'
        },
        {
            code: 'CO004',
            faction: 'arcane',
            name: 'Ollenaxis: Herald of the Dead',
            description: 'Necron is a city hidden deep within the mountains. Its citizens are experts at necromancy and use their abilities to bring back their victims; only to turn them into their slaves.',
            gameplay: 'Ollenaxis gets stronger whenever a unit is summoned from the grave; an ability his Necron allies are all to willing to perform.'
        },
        {
            code: 'CO005',
            faction: 'nature',
            name: 'Elphina: Totem-Whisperer',
            description: 'The Totemic shamans exist in pure harmony with the four elements. Their protective totems channel the elemental energy and focus that energy to harm foes or defend allies. These totems cannot move on the board, but they make up for that with sheer power.',
            gameplay: 'Elphina gets stronger when she is standing next to a totem, shielding herself from all sides.'
        },
        {
            code: 'CO006',
            faction: 'nature',
            name: 'Hillyfig: Queen of the Sylvan Court',
            description: 'The Sylvanii Forest is home to the Sylvanii Fairies. These creatures are extremely adept at healing themselves and eachother; if they are not hiding from danger that is. Whenever they are healed, they unleash powerful offensive abilities.',
            gameplay: 'Hillyfig gets stronger whenever an ally is healed. Whenever any Sylvanii unit is healed, their powerful effects activate.'
        },
        {
            code: 'CO007',
            faction: 'nimble',
            name: 'Yvette: The Nimble',
            description: 'The Shadowstalkers are a group of Rogues, hiding high up in the trees. They are experts at laying traps; which can hurt or enfeeble their opponents with ease.',
            gameplay: 'Yvette can decide to move again instead of attacking. If she does so, a Trap card can be returned from the grave; escaping from harm while bolstering her defenses.'
        },
        {
            code: 'CO008',
            faction: 'nimble',
            name: 'Wayne: King of Thieves',
            description: 'Lowtown is a district in Cooros City where most of its impoverished citizens reside. Thieves guilds have made the Lowtown sewers their home.',
            gameplay: 'Wayne is an expert scam artist who is able to trade any card in his hand for a Fake Gold Pocket Watch, a powerful card which can be stolen from other units for free resources.'
        }
    ]

    const [hero, setHero] = useState(heroes[0])

    return <Section id="showcase">
        <InnerSection>
            <h2>Showcase</h2>
            <p>Meet the <strong>8 heroes</strong> that will be available at launch!</p>
            <OuterGrid>
                <HeroGrid>
                    {heroes.map(tHero => <HeroButton key={tHero.name} $active={tHero.name === hero.name} onClick={() => setHero(tHero)} onMouseEnter={() => setHero(tHero)}>
                        <img height={150} width={150} src={`images/heroes/${tHero.code}.jpg`} alt={tHero.name} />
                    </HeroButton>)}
                </HeroGrid>
                <HeroDescription>
                    <h2>{hero.name}</h2>
                    <p>
                        {hero.description}
                        <span>{hero.gameplay}</span>
                    </p>
                    <div>
                        <img height={50} width={50} alt={`${hero.faction} faction logo`} src={`images/factions/${hero.faction}.png`} />
                        <span>{hero.faction}</span>
                    </div>
                </HeroDescription>
            </OuterGrid>
        </InnerSection>
    </Section>
}

const Section = styled.section`
    background-color: #3d0c08;
    padding: 2rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
`

const OuterGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 215px 1fr;
    gap: 30px;
    margin-top: 3rem;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`

const HeroGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 7px;
    width: 100%;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
        width: calc(100% - 15px);
        margin-bottom: 10px;
    }
`

const HeroButton = styled.button`
    padding: 0;
    outline: none;
    border: 0;
    border-radius: 12px;
    aspect-ratio: 1;
    background: none;
    max-width: 100px;
    border: 3px solid ${props => props.$active ? 'var(--main-color)' : '#2d0705'};
    transition: all 0.2s ease;
    cursor: pointer;
    height: -webkit-fill-available;

    img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
    }

    &:hover {
        border-color: var(--main-color);
    }
`

const HeroDescription = styled.div`
    background-color: #0c0201;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;

    h2 {
        margin: 0;
        padding: 0;

        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid var(--main-color);
    }

    p {
        flex: 1;
        margin: 0;
        padding-bottom: 30px;
        font-weight: bold;

        span {
            display: block;
            margin-top: 30px;
            font-weight: normal;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            filter: invert(1);
            opacity: 0.5;
            width: 50px;
            height: 50px;
        }

        span {
            display: block;
            opacity: 0.5;
            margin-top: 10px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
`