import React from 'react'
import styled from 'styled-components'

export default function TopMenu() {
    return <Menu>
        <a href="#home" title="Home">Home</a>
        <a href="#about" title="About">About</a>
        <a href="#showcase" title="Showcase">Showcase</a>
        <a href="#follow-us" title="Follow Us">Follow us</a>
    </Menu>
}

const Menu = styled.nav`
    background-image: url(images/bg.png);
    background-repeat: repeat;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    border-bottom: 2px solid var(--gold);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;

    a {
        text-decoration: none;
        font-family: Lato;
        font-weight: bold;
        font-size: 1.2rem;
        text-transform: uppercase;
        
        @media screen and (max-width: 768px) {
            font-size: 0.8rem;
        }
    }
`