import React from 'react'
import styled from 'styled-components'

export default function TopSection() {
    return <Header id="home">
        <Overlay />
        <img width={500} height={409} src="images/logo.png" alt="FeatherOak Logo" />
    </Header>
}

const Header = styled.header`
    padding: 5rem 0;
    text-align: center;

    background-image: url(images/top_header.jpg);
    background-size: cover;
    background-position: center center;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    margin-top: 57px;

    img {
        width: 65vw;
        max-width: 350px;
        position: relative;
        z-index: 3;

        height: auto;
        object-fit: contain;
    }

    @media screen and (max-width: 768px) {
        margin-top: 49px;
        padding: 3rem 0;
    }
`

const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: black;
    opacity: 0.4;
`