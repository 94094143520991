import AboutSection from "./components/AboutSection";
import TopMenu from "./components/TopMenu";
import TopSection from "./components/TopSection"

import styled from 'styled-components'
import ShowcaseSection from "./components/ShowcaseSection";
import FollowSection from "./components/FollowSection";
import Footer from "./components/Footer";

function App() {
  return <>
    <TopMenu />
    <TopSection />
    <AboutSection />
    <ShowcaseSection />
    <FollowSection />
    <Footer />
  </>;
}

export const InnerSection = styled.div`
    text-align: center;
    max-width: 968px;
    width: 100%;

    ${props => props.$flex && `
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    `}
`

export const SocialLink = styled.a`
  display: block;
  font-size: 40px;
  margin-top: 30px;

  span {
    display: block;
    text-decoration: none;
    font-size: 12px;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }
`

export default App;
