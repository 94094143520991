import React from 'react'
import styled from 'styled-components'

import { InnerSection, SocialLink } from '../App'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'

export default function FollowSection() {
    return <Section id="follow-us">
        <InnerSection>
            <h2>Follow Us</h2>
            <p style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 30 }}>
                <SocialLink href="//instagram.com/featheroakgame/" target="blank">
                    <FontAwesomeIcon icon={faInstagram} />
                    <span>Follow us</span>
                </SocialLink>
                <SocialLink href="//facebook.com/featheroakgame" target="blank">
                    <FontAwesomeIcon icon={faFacebook} />
                    <span>Like us</span>
                </SocialLink>
            </p>
        </InnerSection>
    </Section>
}

const Section = styled.section`
    background-image: url(images/bg.png);
    background-repeat: repeat;
    padding: 2rem;

    border-top: 2px solid var(--gold);
    
    display: flex;
    justify-content: center;
    align-items: center;
`