import React from 'react'
import styled from 'styled-components'


export default function Footer() {
    return <SFooter>
        {new Date().getFullYear()} &copy; FeatherOak
    </SFooter>
}

const SFooter = styled.footer`
    background-color: black;
    padding: 1rem;
    
    text-align: center;
    font-size: 12px;
`